body {
  overflow: auto;
  background-color: #333333;
}

#root {
  height: 100vh;
  text-align: center;
}

.App-logo {
  height: 45vmin;
  pointer-events: none;
}

.App-header {
  min-height: calc(100% - 96px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  padding: 48px;
}

.Contact {
  font-size: calc(7px + 1.8vmin);
  margin: 0;
}

.App-link {
  color: #61dafb;
}

.Funding {
  max-width: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.Funding-Img {
  width: 150px;
}

.Funding-Text {
  max-width: 540px;
  padding: 0 16px;
  text-align: justify;
  font-size: calc(6px + 0.9vmin);
}